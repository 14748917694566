import { useShopPageProps } from "#app/layouts/ShopLayout";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, IconButton, Tooltip, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import PostEditDialog from "#app/pages/Shops/Posts/PostEditDialog";
import NickLoading from "#app/components/NickLoading";
import { Post, PostStatus } from "#app/models";
import dayjs from "#app/utils/custom_dayjs";
import NoImage from "#assets/no-image.svg";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { useNickFetch } from "#app/hooks/useNickFetch";
import LocalPostDialog from "../LocalPostDialog";
import { LocalPost } from "#app/models/LocalPost";

type PostListResponse = {
	posts: Post[],
}

export default function ShopPosts() {
	const { shop } = useShopPageProps();
	const [selectedPost, setSelectedPost] = useState<Post | null>(null);
	const [selectedLocalPost, setSelectedLocalPost] = useState<LocalPost | null>(null);
	const [postList, setPostList] = useState<Post[]>();
	const [showLoading, setShowLoading] = useState<boolean>(true);
	const { nickFetch } = useNickFetch();

	const refleshPosts = async () => {
		const res = await nickFetch("/get_posts/", {
			shop_id: shop.id,
		});
		const data = res.data as PostListResponse;
		if (!res.error && data.posts) {
			// ok.
			const tmp = [];
			for (const post of data.posts) {
				tmp.push(new Post(post));
			}
			setPostList(tmp);
		}
		setShowLoading(false);
	}

	useEffect(() => {
		(async function () {
			await refleshPosts();
		})();
	}, []);

	return (
		<div>
			<Box pb={3}>
				<Typography mt={2} mb={3} sx={{ fontSize: ".9rem", fontWeight: "bold" }}>投稿</Typography>
				{/* 管理画面で作成した投稿やホットペッパーブログから取り込んだ投稿の一覧<br /> */}
				<Button variant="contained" onClick={() => { setSelectedPost(new Post()) }}>新規作成</Button>
			</Box>
			<PostEditDialog
				open={selectedPost !== null}
				shop_id={shop.id}
				post_id={selectedPost?.id}
				onClose={() => { setSelectedPost(null) }}
				onComplete={refleshPosts}
			/>
			<LocalPostDialog
				open={selectedLocalPost !== null}
				onClose={() => { setSelectedLocalPost(null) }}
				localPost={selectedLocalPost}
			/>
			<NickLoading display={showLoading} />

			<Grid container spacing={2}>
				{postList?.map((post, index) => {
					const localPost = post.get_local_post();
					return (
						<Grid key={index} item xl={2} lg={3} md={4} xs={6}>
							<Card sx={{ position: "relative" }}>
								<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)" }}>
									<Tooltip title={post.getStatusDetail()} placement="top">
										<Typography variant="caption" style={{color:post.status === PostStatus.POSTING_ERROR ? "red" : "none"}}>{post.getStatusLabel()}</Typography>
									</Tooltip>
									<Typography variant="caption" mx="4px" color="#888">/</Typography>
									<Typography variant="caption">{post.getSourcePlatformLabel()}</Typography>
								</Box>
								<CardActionArea onClick={() => { setSelectedLocalPost(localPost) }}>
									<CardMedia
										component="img"
										height="220px"
										sx={{ backgroundColor: "#FFF" }}
										src={localPost.getThumbnailUrl() || NoImage}
										alt="画像"
									/>
									<Box sx={{ height: "80px", background: "linear-gradient(rgba(0,0,0,.54),transparent)", position: "absolute", top: 0, width: "100%" }}>
										<Typography variant="body2" color="#fff" ml={1} mt={1}>
											{localPost.createTime === localPost.updateTime ? (
												<>{dayjs(localPost.createTime).isValid() ? "投稿: " + dayjs(localPost.createTime).format("YYYY年M月D日(dd)") : ""}</>
											) : (
												<>更新: {dayjs(localPost.updateTime).format("YYYY年M月D日(dd)")}</>
											)}
										</Typography>
									</Box>
									<CardContent sx={{ pb: 0, pt: 1 }}>
										<Chip icon={localPost.getTopicTypeIcon()} label={localPost.getTopicTypeLabel()} size="small" variant="outlined" />
										{/* 投稿直後は処理中となっちゃって、全部処理中みたいな表示がキモいので隠す. */}
										{localPost.state !== "PROCESSING" ? (
											<Chip 
												label={localPost.getStateLabel()} 
												sx={{ ml: 1 }} 
												size="small" 
												variant="outlined" 
												color={localPost.getStateColor()} />
										):null}
									</CardContent>
									<CardContent sx={{ pb: 2, pt: 1 }}>
										<Typography variant="body2" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
											{localPost.summary ? (localPost.summary) : (<Box component="span" color="text.secondary">本文なし</Box>)}
										</Typography>
									</CardContent>
								</CardActionArea>
								<CardActions sx={{ py: 0, backgroundColor: "rgba(0,0,0,0.03)" }}>
									{/* <Box sx={{ py: 1, pl: 1 }}>
										<Typography sx={{ fontSize: ".75rem" }}>{post.getStatusLabel()}</Typography>
										<Typography sx={{fontSize:".75rem"}}>{dayjs(post.post_dt).format("YYYY-M-D HH:mm")}</Typography>
										<Typography sx={{ fontSize: ".75rem" }}>{post.getSourcePlatformLabel()}</Typography>
									</Box> */}
									<Typography sx={{ fontSize: ".75rem" }}>{post.platform_post_id}</Typography>
									<IconButton
										aria-label="settings" sx={{ ml: "auto" }}
										onClick={() => { setSelectedPost(post); }}
									>
										<ModeEditRoundedIcon />
									</IconButton>
								</CardActions>
							</Card>
						</Grid>
					)
				})}
			</Grid>
		</div>
	);
}
